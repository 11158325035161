import React, {Component} from 'react';
import PageWrapper from '../components/page-wrapper/page-wrapper.component';
import SEO from '../components/seo';

import './request-conversation.scss';
class RequestConversation extends Component {

  constructor(props) {
    super(props);
    this.title = "Schedule a Conversation";
    this.description = "Schedule a conversation with an Acacia Pharma representative to discuss Barhemsys® (amisulpride).";
    this.setScriptSettings = this.setScriptSettings.bind(this);
  }

  createScript(data, type) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    type === "src" ? script.src = data : script.innerHTML = data;
    document.body.appendChild(script);
  }

  setScriptSettings() {
    const settings = `var ss_form = {'account': 'MzawMLEwMDUxAgA', 'formID': 's0xNtjBISkvTNTQDEibGBia6SWbJhromyanJhibGKYkppkYA'};
    ss_form.width = '100%';
    ss_form.domain = 'app-3QNLPQABDS.marketingautomation.services';
    ss_form.hidden = {'_usePlaceholders': true};
    ss_form.target_id = 'sharpspring-form';
    ss_form.polling = true;`;
  this.createScript(settings, "script");
  this.createScript("https://koi-3QNLPQABDS.marketingautomation.services/client/form.js?ver=2.0.1", "src");
}

componentDidMount() {
  // Use magic number to delay script setting.
  setTimeout(this.setScriptSettings, 1500);
}

componentDidUpdate() {
  this.setScriptSettings();
}

render () {
  return (
    <PageWrapper headline="Request a Conversation"  heroBanner="Schedule a Conversation" showIsi={true}>
      <SEO title={this.title} description={this.description} />
      <div className="content">
        <div id="sharpspring-form" />
      </div>
    </PageWrapper>
  );
}
}

export default RequestConversation;
